



































import {defineComponent, PropType} from '@vue/composition-api';
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import TableInput from '@/tasks/components/inputs/TableInput.vue';
import {VTextField} from 'vuetify/lib/components';
import CalculationInput from '@/tasks/components/inputs/CalculationInput.vue';

export default defineComponent({
  name: 'uOttawa1321Lab4Question1',
  components: {TableInput, STextarea, CalculationInput, VTextField},
  mixins: [DynamicQuestionMixin()],
  props: {
    isMarking: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },
  data() {
    const browserLanguage = navigator.language.split('-')[0]; // Extract the language code
    return {
      inputs: {
        aiQuestionResponse: null,
        language: browserLanguage === 'fr' ? 'fr' : 'en', // Set the initial language based on browser preference
      },
      questions: [
        {
          en: '“A compound can oil out if the boiling point of the recrystallization solvent is higher than the melting point of the compound”. Is this statement true or false? Explain your answer.',
          fr: "“Une substance peut séparer sous forme d'huile si le point d'ébullition du solvant de recristallisation est plus élevé que le point de fusion de la substance”. Cette déclaration est-elle vraie ou fausse ? Expliquez votre réponse.",
        },
      ],
    };
  },
  methods: {
    getQuestionText(question: any): string {
      return this.inputs.language === 'en' ? question.en : question.fr;
    },
  },
});
